import request from '@/utils/request'
import consts from '@/utils/consts'
// 用户注册 & 忘记密码
export function apiRegister(data) {
	return request({
		url: consts.businessOnly + '/anonymous/user/registerAccountNo',
		method: 'post',
		data: data
	})
}

// 用户登录
export function apiLogin(data) {
	const tData = {
		...data,
		appTerm:"H5",
		userType:"C",
	}
	return request({
		url: consts.businessOnly + '/anonymous/user/login',
		method: 'post',
		data: tData
	})
}

// 修改密码
export function apiChangePassword(data) {
	return request({
		url: consts.businessPublic + '/user/changePassword',
		method: 'post',
		data: data
	})
}

// 设置密码
export function apiSetPassword(data) {
	return request({
		url: consts.businessPublic + '/user/setPassword',
		method: 'post',
		data: data
	})
}

// 用户信息初始化，通过userId获取用户个人信息
export function apiUserInfo(data) {
	return request({
		url: consts.businessPublic + '/user/userInfoInit',
		method: 'post',
		data: data
	})
}
// 退出方法
export function apiLogout(data) {
	return request({
		url: consts.businessPublic + '/user/logout',
		method: 'post',
		data: data
	})
}
// 获取短信验证码
export function apiGetCode(data) {
	return request({
		// url: '/captchaImage',
		url: consts.businessOnly + '/anonymous/sms/send',
		method: 'POST',
		data: data
	})
}

// 实名认证
export function apiUserNameAuth(data) {
	return request({
		url: consts.businessPublic + '/user/userNameAuth',
		method: 'post',
		data: data
	})
}

// 查询彩票店列表
export function apiLotteryShopList(data) {
	return request({
		url: consts.businessPublic + '/user/lotteryShopList',
		method: 'post',
		data: data
	})
}

// 绑定彩票店
export function apiBindLottery(data) {
	return request({
		url: consts.businessPublic + '/user/bindLottery',
		method: 'post',
		data: data
	})
}


// 查询角色列表
export function apiQueryUserRole(data) {
	return request({
		url: consts.businessPublic + '/user/queryUserRole',
		method: 'post',
		data: data
	})
}


// 新增/修改角色
export function apiUserRole(data) {
	return request({
		url: consts.businessPublic + '/user/userRole',
		method: 'post',
		data: data
	})
}


// 角色：方案信息同步
export function apiSyncRolePlan(data) {
	return request({
		url: consts.businessPublic + '/user/syncRolePlan',
		method: 'post',
		data: data
	})
}

