import {
	createStore
} from 'vuex'
import userInfo from './modules/userInfo.js'
import other from './modules/other.js'
import allPlay from './modules/allPlay.js'
export default createStore({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		userInfo,
		other,
		allPlay
	}
})
