import axios from 'axios';
import {
	Toast
} from 'vant';
import store from '@/store';
import Lockr from '@/utils/lockr';
import uConfig from '@/utils/config';

const errorCode = {
	'401': '长时间未操作，请重新登录',
	'default': '系统未知错误，请反馈给管理员'
};

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: uConfig[process.env.VUE_APP_TITLE].baseUrl,
	// 超时
	timeout: 10000
})
// 请求拦截器
service.interceptors.request.use(config => {
	// console.log(config)
	// 是否需要设置 token
	// const isToken = (config.headers || {}).isToken === false;
	// console.log(isToken);
	// console.log(Lockr.get("userInfo/S_TOKEN"))
	if (Lockr.get("userInfo/S_TOKEN")) {
		config.headers['token'] = Lockr.get("userInfo/S_TOKEN") // 让每个请求携带自定义token 请根据实际情况自行修改
	}
	if (Lockr.get("userInfo/S_LOGININFO")) {
		const {
			roleId
		} = Lockr.get("userInfo/S_LOGININFO");
		config.headers['role'] = roleId ? roleId : 0;
	} else {
		config.headers['role'] = 0;
	}
	config.headers['p'] = "H5";//平台
	config.headers['v'] = "1.0.0";//版本号
	config.headers['subAppId'] = Lockr.get('other/S_SUBAPPID') ? Lockr.get('other/S_SUBAPPID') : null;// 店铺ID
	// get请求映射params参数
	if (config.method === 'get' && config.params) {
		let url = config.url + '?';
		for (const propName of Object.keys(config.params)) {
			const value = config.params[propName];
			var part = encodeURIComponent(propName) + "=";
			if (value && typeof(value) !== "undefined") {
				if (typeof value === 'object') {
					for (const key of Object.keys(value)) {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + "=";
						url += subPart + encodeURIComponent(value[key]) + "&";
					}
				} else {
					url += part + encodeURIComponent(value) + "&";
				}
			}
		}
		url = url.slice(0, -1);
		config.params = {};
		config.url = url;
	}
	return config
}, error => {
	console.log(error)
	return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
		// console.log("++++++++++++成功，下面是接口响应数据+++++++++++++++");
		// console.log(res);
		Toast.clear();
		// {code: "200", success: true, message: "操作成功!", result: "OK"}
		// 未设置状态码则默认成功状态
		const code = res.data.code || 500;
		// 获取错误信息
		const msg = errorCode[code] || res.data.message || errorCode['default']
		if (code == 401) {
			Toast({
				message: msg,
				position: 'bottom'
			});
			// 自动登录并删除所有保存信息
			store.dispatch('userInfo/FedLogOut').then(() => {
				location.href = '?subAppId='+ Lockr.get('other/S_SUBAPPID') +'/#/loginPw';
			})
			return Promise.reject(res.data)
		} else if (code == 500) {
			Toast({
				message: msg,
				position: 'bottom'
			});
			return Promise.reject(res.data)
		} else if (code != 200 && res.config && res.config.data && JSON.parse(res.config.data) && JSON.parse(res.config.data).noToast) {
			return Promise.reject(res.data);
		} else if (code != 200) {
			Toast({
				message: msg,
				position: 'bottom'
			});
			return Promise.reject(res.data)
		} else {
			return Promise.resolve(res.data || {})
		}
	},
	// 统一错误处理
	(error, data) => {
		Toast.clear();
		console.log('-------------失败-------------');
		console.log(error, data);
		let {
			message
		} = error;
		let tCode = null;
		tCode = message.substr(message.length - 3);

		// 自定义HTTP请求错误Code
		let HTTPErrCode = null;
		if (tCode === '401') {
			Toast({
				message: '请重新登录！',
				position: 'bottom'
			});
			// 自动登录并删除所有保存信息
			return store.dispatch('userInfo/FedLogOut').then(() => {
				location.href = '?subAppId='+ Lockr.get('other/S_SUBAPPID') +'/#/loginPw';
			})
		} else if (message == "Network Error") {
			message = "网络连接不稳定，请稍后再试";
			HTTPErrCode = 4042
		} else if (message.includes("timeout")) {
			message = "请求超时，请稍后再试";
			HTTPErrCode = 4043
		} else if (message.includes("Request failed with status code")) {
			message = "系统接口" + tCode + "异常";
		}
		Toast({
			message: message,
			position: 'bottom'
		});
		if(HTTPErrCode){
			return Promise.reject({
				code:HTTPErrCode
			})
		}else{
			return Promise.reject(error)
		}
	}
)
/**
 * HTTP 错误 404
 404 找不到
 Web 服务器找不到您所请求的文件或脚本。请检查URL 以确保路径正确。
 * HTTP 错误 4041
 4041 自定义错误 意为请求数据为空
 * HTTP 错误 4042
 4042 自定义错误 意为网络连接不稳定，请稍后再试
 * HTTP 错误 4043
 4043 自定义错误 意为请求超时，请稍后再试
 */
export default service
