/**
 * @authors hjs
 * @description  自定义标识符介绍
 * @S_变量：set设置  例如：S_USER 同时用于存储到local
 */
// userInfo
export const S_TOKEN = "userInfo/S_TOKEN"; // 令牌
export const S_LOGININFO = "userInfo/S_LOGININFO"; // 登录信息
export const S_USERINFO = "userInfo/S_USERINFO"; // 用户信息
export const S_MYINFO = "other/S_MYINFO"//更新我的信息

// other
export const S_SROUTERFROM = "other/S_SROUTERFROM"//上个页面的路由来源
export const S_SEDITOR = "other/S_SEDITOR"//富文本内容
export const S_OPTID = "other/S_OPTID"//平台ID
export const S_SUBAPPID = "other/S_SUBAPPID"//彩店ID

export const S_SCROLLTOP = "other/S_SCROLLTOP"//更新滚动距离
export const S_EYESHOW = "other/S_EYESHOW"//更新滚动距离
export const S_NOTICEDETAIL = "other/S_NOTICEDETAIL"//消息详情
export const S_FOLLOWORDERSEARCH = "other/S_FOLLOWORDERSEARCH"//更新滚动距离
export const S_WITHDRAWINFO = "other/S_WITHDRAWINFO"// 提现信息

// allPlay
export const S_FBSELECTED = "allPlay/S_FBSELECTED"; // 足球已选选项
export const S_BBSELECTED = "allPlay/S_BBSELECTED"; // 篮球已选选项
export const S_BJDCSELECTED = "allPlay/S_BJDCSELECTED"; // 北京单场已选选项
export const S_BETSELECTED = "allPlay/S_BETSELECTED"; // 流量APP已选选项
export const S_ORDERDATA = "allPlay/S_ORDERDATA"; // 已确定订单数据