import * as types from '../mutation-types';
import Lockr from '@/utils/lockr.js';
import { fnDeepClone } from "@/utils/public.js";

// football => 简写 FB
// basketball => 简写 FB
const allPlay = {
	namespaced: true, // 命名空间
	state: {
		// 足球已选数据
		FBSelected: Lockr.get(types.S_FBSELECTED) || [],
		// 篮球已选数据
		BBSelected: Lockr.get(types.S_BBSELECTED) || [],
		// 北京单场已选数据
		BJDCSelected: Lockr.get(types.S_BJDCSELECTED) || {
			sfgg: [],
			spf:  [],
			dcbf:  [],
			jqs:  [],
			bqcspf:  [],
		},
		// 已确定订单数据
		orderData: Lockr.get(types.S_ORDERDATA) || {},
		
		// 流量APP选择赛事
		BETSelected: Lockr.get(types.S_BETSELECTED) || {},
	},

	mutations: {
		[types.S_FBSELECTED]: (state, data) => {
			state.FBSelected = data;
		},
		[types.S_BBSELECTED]: (state, data) => {
			state.BBSelected = data;
		},
		[types.S_BJDCSELECTED]: (state, data) => {
			const tCData = data && data.sfgg ? fnDeepClone(data) : {
				sfgg: [],
				spf:  [],
				dcbf:  [],
				jqs:  [],
				bqcspf:  [],
			};
			state.BJDCSelected = tCData;
		},
		
		[types.S_ORDERDATA]: (state, data) => {
			state.orderData = data;
		},
		[types.S_BETSELECTED]: (state, data) => {
			state.BETSelected = data;
		}
	},

	actions: {
		/**
		 * [UBETSelected  更新足球已选数据]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[array]} data [ 已选数据 ]
		 * @return null
		 */
		UBETSelected({
			commit
		}, data) {
			const tCData = fnDeepClone(data)
			Lockr.set(types.S_BETSELECTED, tCData);
			commit(types.S_BETSELECTED, tCData);
		},
		/**
		 * [UFBSelected  更新足球已选数据]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[array]} data [ 已选数据 ]
		 * @return null
		 */
		UFBSelected({
			commit
		}, data) {
			const tCData = fnDeepClone(data)
			Lockr.set(types.S_FBSELECTED, tCData);
			commit(types.S_FBSELECTED, tCData);
		},
		/**
		 * [UBBSelected  更新篮球已选数据]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[array]} data [ 已选数据 ]
		 * @return null
		 */
		UBBSelected({
			commit
		}, data) {
			const tCData = fnDeepClone(data)
			Lockr.set(types.S_BBSELECTED, tCData);
			commit(types.S_BBSELECTED, tCData);
		},
		/**
		 * [UBJDCSelected  更新北京单场已选数据]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[array]} data [ 已选数据 ]
		 * @return null
		 */
		UBJDCSelected({
			commit
		}, data) {
			const tCData = data && data.sfgg ? fnDeepClone(data) : {
				sfgg: [],
				spf:  [],
				dcbf:  [],
				jqs:  [],
				bqcspf:  [],
			};
			console.log(tCData);
			Lockr.set(types.S_BJDCSELECTED, tCData);
			commit(types.S_BJDCSELECTED, tCData);
		},
		/**
		 * [UOrderData  更新订单数据]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[Object]} data [ 已确定订单数据 ]
		 * @return null
		 */
		UOrderData({
			commit
		}, data) {
			Lockr.set(types.S_ORDERDATA, data);
			commit(types.S_ORDERDATA, data);
		},
		/**
		 * [CleanAllPlayData 清除AllPlay中的数据]
		 * @param  {[Object]} {dispatch} [  ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		CleanAllPlayData({
			commit
		}) {
			console.log("调起CleanAllPlayData");
			commit(types.S_FBSELECTED, []);
			commit(types.S_BBSELECTED, []);
			commit(types.S_BJDCSELECTED, {});
			commit(types.S_BETSELECTED, {});
			commit(types.S_ORDERDATA, {});
			Lockr.rm(types.S_FBSELECTED);
			Lockr.rm(types.S_BBSELECTED);
			Lockr.rm(types.S_BJDCSELECTED);
			Lockr.rm(types.S_BETSELECTED);
			Lockr.rm(types.S_ORDERDATA);
		},
	}
}

export default allPlay
