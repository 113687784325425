import * as types from '../mutation-types';
import Lockr from '@/utils/lockr.js';

// 页面刷新时直接从本地获取数据
const other = {
	namespaced: true, // 命名空间
	state: {
		// 上个页面的路由来源
		sRouterFrom: Lockr.get(types.S_SROUTERFROM) ? Lockr.get(types.S_SROUTERFROM) : null,
		// 富文本内容
		sEditor: Lockr.get(types.S_SEDITOR) ? Lockr.get(types.S_SEDITOR) : null,
		// 平台跳转H5写到的平台方案ID

		oPTId: Lockr.get(types.S_OPTID) ? Lockr.get(types.S_OPTID) : {
			isUse: true
		},

		// 金额-小眼睛显示状态
		eyeShow: Lockr.get(types.S_EYESHOW) === undefined ? true : Lockr.get(types.S_EYESHOW),
		// 消息详情
		noticeDetail: Lockr.get(types.S_NOTICEDETAIL) ? Lockr.get(types.S_NOTICEDETAIL) : {},
		// 提现信息
		withdrawInfo: Lockr.get(types.S_WITHDRAWINFO) ? Lockr.get(types.S_WITHDRAWINFO) : {},
		// 滚动距离
		oScrollTop: Lockr.get(types.S_SCROLLTOP) ? Lockr.get(types.S_SCROLLTOP) : {},
	},

	mutations: {
		// 上个页面的路由来源
		[types.S_SROUTERFROM]: (state, res) => {
			state.sRouterFrom = res;
		},
		// 上个页面的路由来源
		[types.S_SEDITOR]: (state, res) => {
			state.sEditor = res;
		},
		// 平台跳转H5写到的平台方案ID
		[types.S_OPTID]: (state, res) => {
			state.oPTId = res;
		},

		// 存储眼睛开关
		[types.S_EYESHOW]: (state, res) => {
			state.eyeShow = res;
		},
		// 存储消息详情
		[types.S_NOTICEDETAIL]: (state, res) => {
			state.noticeDetail = res;
		},
		// 存储提现信息
		[types.S_WITHDRAWINFO]: (state, res) => {
			state.withdrawInfo = res;
		},
		// 存储滚动距离
		[types.S_SCROLLTOP]: (state, res) => {
			state.oScrollTop = res;
		},
	},
	// U + 方法名
	actions: {
		/**
		 * [USRouterFrom  更新上个页面的路由来源]
		 * @param  {[Object]} {commit} [  ]
		 * @return
		 */
		USRouterFrom({
			commit
		}, res) {
			Lockr.set(types.S_SROUTERFROM, res);
			commit(types.S_SROUTERFROM, res);
		},
		/**
		 * [USEDITOR  更新Editor内容]
		 * @param  {[Object]} {commit} [  ]
		 * @return
		 */
		USEDITOR({
			commit
		}, res) {
			Lockr.set(types.S_SEDITOR, res);
			commit(types.S_SEDITOR, res);
		},
		/**
		 * [UOPTId  更新平台（PT）ID]
		 * @param  {[Object]} {commit} [  ]
		 * @return
		 */
		UOPTId({
			commit
		}, res) {
			Lockr.set(types.S_OPTID, res);
			commit(types.S_OPTID, res);
		},
		/**
		 * [UEyeShow  更新金额-小眼睛显示状态]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[Booler]} （true) [ 控制小眼睛打开关闭 ]
		 * @return
		 */
		UEyeShow({
			commit
		}, res) {
			Lockr.set(types.S_EYESHOW, res);
			commit(types.S_EYESHOW, res);
		},
		/**
		 * [UNoticeDetail  更新消息详情]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[Object]} [ 消息详情对象 ]
		 * @return
		 */
		UNoticeDetail({
			commit
		}, res) {
			Lockr.set(types.S_NOTICEDETAIL, res);
			commit(types.S_NOTICEDETAIL, res);
		},
		/**
		 * [UWithdrawInfo 更新提现信息]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[Object]} [ 消息详情对象 ]
		 * @return
		 */
		UWithdrawInfo({
			commit
		}, res) {
			Lockr.set(types.S_WITHDRAWINFO, res);
			commit(types.S_WITHDRAWINFO, res);
		},

		/**
		 * [UScrollTop 更新页面滚动位置]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[Object]} [ 滚动位置对象 ]
		 * @return
		 */
		UScrollTop({
			commit
		}, res) {
			Lockr.set(types.S_SCROLLTOP, res);
			commit(types.S_SCROLLTOP, res);
		},

		/**
		 * [CleanOtherData 清除Other中的数据]
		 * @param  {[Object]} {commit,state} [  ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		CleanOtherData({
			commit
		}) {
			console.log("调起CleanOtherData");
			commit(types.S_EYESHOW, true);
			commit(types.S_NOTICEDETAIL, {});
			commit(types.S_WITHDRAWINFO, {});
			Lockr.rm(types.S_EYESHOW);
			Lockr.rm(types.S_NOTICEDETAIL);
			Lockr.rm(types.S_WITHDRAWINFO);

			commit(types.S_SROUTERFROM, null);
			Lockr.rm(types.S_SROUTERFROM);

			commit(types.S_SEDITOR, null);
			Lockr.rm(types.S_SEDITOR);

			commit(types.S_OPTID, {isUse: true});
			Lockr.rm(types.S_OPTID);
		},
	}
}

export default other
