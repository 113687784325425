import Lockr from 'lockr'
Lockr.prefix = 'lockr';
export default Lockr;

/**
 * 
 * lockr 帮助你轻松使用localStorage，让使用本地存储保存对象、数组、数字、字符串省略很多步骤。
 * 
 * Lockr.prefix - String
 * 设置一个前缀，将被添加到每个键被锁的字符串值
 * 
 * Lockr.set - 参数: [ key, value ] {String, Number, Array or Object}
 * 设置一个指定的值，可以是任意类型
 * 
 * Lockr.get - 参数: [ key or hash_key, default value ]
 * 通过给定的键返回被保存的值，如果指定的键是null或undefined则会返回一个默认值
 * 
 * Lockr.rm - 参数: [ key ] {String}
 * 完全删除指定的键值对=>对于不用的数据建议直接rm
 * 
 * Lockr.sadd - 参数 key, value ]{String, Number, Array or Object}
 * 追加一个值在之前的基础上面
 * 
 * Lockr.sismember- 参数 key, value ]{String, Number, Array or Object}
 * 判断指定键是否存在那个值
 * 
 * Lockr.srem - 参数 key, value ]{String, Number, Array or Object}
 * 移除指定键下的那个值
 * 
 * Lockr.getAll
 * 获取本地存储中所有的键值对
 */

/**
 * [transformH5Object 对数据进行加密格式封装]
 * @param  {[type]} method [description]
 * @param  {[type]} params [description]
 * @param  {[OBject]} isEncryption [该项目是否加密]
 * @return {[type]}        [description]
 */
