<template>
<!--	<div :style="isApp ? 'padding-top: 1.333rem;box-sizing: border-box' : ''">-->
		<!-- 这里是会被缓存的视图组件，比如 Home！ -->
		<!-- <keep-alive >
            <router-view />
        </keep-alive> -->
		<!-- vue3.0配置 -->
		<router-view v-slot="{ Component }">
			<keep-alive :max="10" v-if="bIsRouterAlive">
				<component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path" />
			</keep-alive>
			<component :is="Component" v-if="!$route.meta.keepAlive" />
		</router-view>
		<!-- <router-view v-if="!$route.meta.keepAlive" /> -->
		<van-tabbar class="app_tabbar" v-if="!$route.meta.hidden ? true : false" v-model="active" route
					active-color="#F04A4A" inactive-color="#999999">
			<van-tabbar-item to="/">
				首页
				<template #icon="props">
					<img :src="props.active ? tabbar.home.activeIcon : tabbar.home.inactiveIcon" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item to="/order">
				跟单大厅
				<template #icon="props">
					<img :src="props.active ? tabbar.order.activeIcon : tabbar.order.inactiveIcon" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item to="/plan">
				方案
				<template #icon="props">
					<img :src="props.active ? tabbar.plan.activeIcon : tabbar.plan.inactiveIcon" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item to="/mine">
				我的
				<template #icon="props">
					<img :src="props.active ? tabbar.mine.activeIcon : tabbar.mine.inactiveIcon" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
<!--	</div>-->


</template>

<script>
	import {
		ref,
		reactive,
		nextTick,
		provide
	} from "vue"
	export default {
		name: "App",
		setup() {
			// 控制 router-view 是否显示达到刷新效果
			const bIsRouterAlive = ref(true);
			const fReload = () => {
				// 通过 bIsRouterAlive 控制 router-view 达到刷新效果
				bIsRouterAlive.value = false
				nextTick(() => {
					bIsRouterAlive.value = true
				})
			};

			// 当前路由数据
			// const oCPRoute = reactive({
			// 	hidden : false,//是否展示
			// 	keepAlive : false//是否缓存
			// })
			const active = ref(0);
			const tabbar = reactive({
				home: {
					activeIcon: require("@/assets/images/tabbarHomeOn.png"),
					inactiveIcon: require("@/assets/images/tabbarHomeOff.png")
				},
				order: {
					activeIcon: require("@/assets/images/tabbarPlanOn.png"),
					inactiveIcon: require("@/assets/images/tabbarPlanOff.png")
				},
				plan: {
					activeIcon: require("@/assets/images/tabbarPlanOn.png"),
					inactiveIcon: require("@/assets/images/tabbarPlanOff.png")
				},
				mine: {
					activeIcon: require("@/assets/images/tabbarMineOn.png"),
					inactiveIcon: require("@/assets/images/tabbarMineOff.png")
				}
			});


			provide("fGlobalReload", fReload);
			// 判断是否在app内

			var userAgent = navigator.userAgent.toLowerCase();//获取UA信息
			const isApp = userAgent.indexOf("mjhy") != -1 ? true :false

			return {
				active,
				tabbar,
				bIsRouterAlive,
				isApp
			}
		},

	}
</script>

<style lang="scss">

	.home_planInfo {
		padding: 0.0266rem 0.32rem 0.64rem;

		.home_planInfo_title {
			display: flex;
			align-items: center;
			margin: 0.32rem;

			.home_planInfo_title_border {
				width: 0.1066rem;
				height: 0.48rem;
				background: #033EC2;
				border-radius: 0.0533rem;
				margin-right: 0.16rem;
			}

			.home_planInfo_title_a {
				font-size: 0.4266rem;
				font-weight: 600;
				color: #383838;
				margin-right: 0.1066rem;
			}

			.home_planInfo_title_b {
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;
				margin-right: 0.32rem
			}

			.home_planInfo_title_c {
				padding: 0 0.4266rem;
				font-size: 0.32rem;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.4533rem;
				background: #FF7671;
				border-radius: 0.1066rem;
			}
			.home_planInfo_title_d {
				padding: 0.0533rem 0.1066rem;
				font-size: 0.32rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.4533rem;
				background: #FED530;
				border-radius: 0.1066rem;
			}
		}

		.home_planInfo_user {
			display: flex;
			align-items: center;
			padding: 0.32rem;
			background: #FCFCFC;
			box-shadow: 0 0.1066rem 0.4266rem 0 rgba(199, 202, 216, 0.2);
			border-radius: 0.2133rem;
			border: 0.0533rem solid #FFFFFF;

			.home_planInfo_user_headPortrait {
				width: 1.28rem;
				height: 1.28rem;
				border-radius: 50%;
				margin-right: 0.2666rem;
			}

			.home_planInfo_user_info {
				display: flex;
				flex-direction: column;

				.home_planInfo_user_info_top {
					display: flex;
					align-items: center;

					.home_planInfo_user_info_top_name {
						font-size: 0.3733rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.5333rem;
						margin-right: 0.1066rem;
					}

					.home_planInfo_user_info_top_red {
						position: relative;
						display: flex;
						align-items: center;
						padding: 0.0266rem 0.2rem;
						height: 0.4266rem;
						border-radius: 0.2133rem;
						background-image: url('~@/assets/icon/icon-002.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;

						.home_planInfo_user_info_top_red_img {
							position: absolute;
							width: auto;
							height: 0.24rem;
							top: -0.24rem;
							left: 0.1866rem;
						}

						&>em {
							font-size: 0.32rem;
							font-weight: bold;
							color: #E2335D;
							margin-right: 0.0266rem;
						}

						&>span {
							font-size: 0.2133rem;
							font-weight: 500;
							color: #383838;
						}
					}
				}

				.home_planInfo_user_info_time {
					margin-top: 0.08rem;
					font-size: 0.2666rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.3733rem;
				}
			}
		}
	}

	.home_betInfo {
		height: 3.5rem;
		margin: 0.0266rem 0.32rem 0;
		background: #FCFCFC;
		box-shadow: 0px 0.1066rem 0.4266rem 0px rgba(199, 202, 216, 0.2);
		border-radius: 0.2133rem;
		border: 0.0533rem solid #FFFFFF;

		.home_betInfo_title {
			display: flex;
			align-items: center;
			margin: 0.32rem 0.32rem 0;

			.home_betInfo_title_border {
				width: 0.1066rem;
				height: 0.48rem;
				border-radius: 0.0533rem;
				margin-right: 0.16rem;
			}

			.home_betInfo_title_a {
				font-size: 0.4266rem;
				font-weight: 600;
				color: #383838;
				margin-right: 0.1066rem;
			}

			.home_betInfo_title_b {
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;
				margin-right: 0.32rem
			}

		}

		.home_betInfo_time {
			margin: 0.2133rem 0.5866rem 0;
			font-size: 0.2666rem;
			font-weight: 400;
			color: #979797;
			line-height: 0.3733rem;
		}

		.home_betInfo_user {
			margin: 0.2133rem 0.5866rem 0;
			display: flex;
			flex-wrap: wrap;

			&>span {
				margin-right: 0.1066rem;
				margin-bottom: 0.1066rem;
				padding: 0 0.4266rem;
				font-size: 0.32rem;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.4533rem;
				background: #FF7671;
				border-radius: 0.1066rem;
				white-space: nowrap;
			}
		}
	}
</style>
